import React, { useState, useRef, useEffect } from 'react';
import './HeroSection.css';


const HeroSection = () => {
  const image1 = 'assets/images/banner1.png'; 
  const image2 = 'assets/images/banner2.png'; 
  const image3 = 'assets/images/banner1-small.jpg'; 
  const image4 = 'assets/images/banner2-small.jpg'; 

  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false); 
  const [currentTime, setCurrentTime] = useState(0); 
  const [currentImages, setCurrentImages] = useState([image1, image2]);  
  const videoRef = useRef(null);
  const containerRef = useRef(null);

  const video = 'assets/videos/hero1.mp4';
  const video2 = 'assets/videos/hero2.mp4';
  const video3 = 'assets/videos/hero3.mp4';


  const videos = [video, video2, video3];
  const pages = [
    { type: 'video', content: videos },
    { type: 'image', content: currentImages[0] }, 
    { type: 'image', content: currentImages[1] },  
  ];

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1200) {
        setCurrentImages([image3, image4]); 
      } else {
        setCurrentImages([image1, image2]); 
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (videoElement) {
      const handleEnd = () => {
        setIsVideoPlaying(false);
        switchToNextVideo();
      };

      videoElement.addEventListener('ended', handleEnd);
      return () => videoElement.removeEventListener('ended', handleEnd);
    }
  }, [currentPageIndex]);

  const switchToNextVideo = () => {
    setTimeout(() => {
      setCurrentPageIndex((prevIndex) => {
        const nextIndex = (prevIndex + 1) % videos.length;
        return nextIndex;
      });
      setIsVideoPlaying(true);
    }, 10000);  // Espera 10 segundos antes de cambiar al siguiente video
  };

  const handlePlayPauseVideo = () => {
    const videoElement = videoRef.current;
    
    if (videoElement) {
      if (isVideoPlaying) {
        setCurrentTime(videoElement.currentTime);
        videoElement.pause();
      } else {
        videoElement.currentTime = currentTime; 
        videoElement.play();
      }
      setIsVideoPlaying((prevState) => !prevState); 
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVideoPlaying(true);
        } else {
          setIsVideoPlaying(false);
        }
      },
      { threshold: 0.1 }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const handleDotClick = (index) => {
    setCurrentPageIndex(index);
    if (pages[index].type === 'video') {
      setIsVideoPlaying(true);
    } else {
      setIsVideoPlaying(false);
    }
  };

  return (
    <div id="hero" className="hero-section" ref={containerRef}>
      {pages[currentPageIndex].type === 'video' ? (
        <video
          ref={videoRef}
          className={`hero-video ${isVideoPlaying ? 'visible' : 'hidden'}`}
          muted
          playsInline
          width="100%"
          onClick={handlePlayPauseVideo}
          key={currentPageIndex}
          autoPlay
          loop={false}
        >
          <source src={videos[currentPageIndex]} type="video/mp4" />
          Tu navegador no soporta la etiqueta de video.
        </video>
      ) : (
        <img
          src={pages[currentPageIndex].content}
          alt="Page"
          className="hero-image"
          key={currentPageIndex}
        />
      )}

      {currentPageIndex === 0 && (
        <div className={`hero-content ${isVideoPlaying ? 'hidden' : 'visible'}`}>
          <h1>Descubre la Nueva Era de la Gestión Inmobiliaria</h1>
          <h2>Alien Realty</h2>
          <p>
            Nuestra aplicación inmobiliaria modular y multiplataforma está diseñada para transformar
            la forma en que las empresas del sector gestionan sus propiedades, coordinan con brokers,
            aseguran transacciones y optimizan sus listados. Accede a una interfaz moderna y
            intuitiva, disponible tanto en web como en dispositivos móviles.
          </p>
          <div className="hero-buttons-container">
            <a href="https://www.instagram.com/alienrealty" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" className="instagram-btn">
              <img src='assets/images/insta.png' alt="Instagram" className="hero-icon" />
              Síguenos en Instagram
            </a>
            <a href="https://www.facebook.com/profile.php?id=61555710022289" style={{ textDecoration: "none" }} target="_blank" rel="noopener noreferrer" className="facebook-btn">
              <img src='assets/images/facebook.png' alt="Facebook" className="hero-icon" />
              Conoce nuestro Facebook
            </a>
          </div>
        </div>
      )}

      <div className="dots-container">
        {pages.map((_, index) => (
          <span
            key={index}
            className={`dot ${currentPageIndex === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>

      <button className="play-video-btn" onClick={handlePlayPauseVideo}>
        {isVideoPlaying ? '❚❚ Pausar Video' : '▶ Reproducir Video'}
      </button>
    </div>
  );
};

export default HeroSection;
