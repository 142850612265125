import React, { useEffect } from 'react';
import './BenefitsSection.css';

const benefits = [
  {
    title: 'Eficiencia Operativa',
    description: 'Automatiza y optimiza procesos clave.',
    icon: 'assets/images/operation.png'
  },
  {
    title: 'Seguridad de las Propiedades',
    description: 'Protege datos sensibles y transacciones.',
    icon: 'assets/images/security2.png'
  },
  {
    title: 'Mejor Toma de Decisiones',
    description: 'Herramientas de análisis y reportes en tiempo real.',
    icon: 'assets/images/take-decision.png'
  },
  {
    title: 'Comunicación Efectiva',
    description: 'Facilita la coordinación entre brokers.',
    icon: 'assets/images/cowork.png'
  },
  {
    title: 'Mejor Servicio al Cliente',
    description: 'Gestión ágil y eficiente de propiedades.',
    icon: 'assets/images/client-service.png'
  }
];

const BenefitsSection = () => {
  useEffect(() => {
    const items = document.querySelectorAll('.benefit-icon, .benefits-title');
  
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          console.log(`${entry.target.className} is now visible`); // Añadir log para depuración
        }
      });
    }, {
      threshold: 0.1 // Umbral más bajo para activar la intersección antes
    });
  
    items.forEach(item => {
      observer.observe(item);
    });
  
    return () => {
      items.forEach(item => {
        observer.unobserve(item);
      });
    };
  }, []);
  

  return (
    <section id="benefits" className="benefits-section">
      <div className="ufo-content">
        <div className="title-container">
          <h3 className="benefits-title">Nuestros Beneficios</h3>
        </div>
        <div className="benefits-list">
          {benefits.map((benefit, index) => (
            <div key={index} className="benefit-item">
              <img src={benefit.icon} alt={benefit.title} className="benefit-icon" />
              <span className="benefit-item-text">{benefit.title}</span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;
